<template>
  <base-section
    id="who-am-i"
    class="grey lighten-3"
    space="30"
  >
    <v-container>
      <base-section-heading title="Qui suis-je?">
        <p class="who-am-i-text">Après un passage sur le marché du travail, je me suis découvert une passion pour la comptabilité ainsi que ses domaines connexes. </p>
        <p class="who-am-i-text">Maintenant <b>candidat à la profession de comptable professionnel agréé</b>, je suis détenteur d’un <b>baccalauréat en sciences comptables</b> ainsi que d’un <b>certificat en planification financière personnelle.</b></p>
        <p class="who-am-i-text">J’aime discuter avec vous, répondre à vos interrogations et avoir de la rétroaction afin de m’améliorer et de vous offrir le meilleur service possible.</p>
        <p class="who-am-i-text">Faire affaire avec moi, c’est s’assurer d’une déclaration de revenus optimale vous permettant de réduire au plus bas votre impôt à payer !</p>
        <div style="margin-top: 30px;">
          <base-btn to="contact">
            Prendre rendez-vous avec moi
          </base-btn>
        </div>
      </base-section-heading>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'WhoAmI',
  }
</script>

<style scoped>
.who-am-i-text {
  font-size: 20px;
  line-height: 30px;
}
</style>
